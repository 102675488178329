<template>
    <div :class="classList"
         class="column">
        <slot/>
    </div>
</template>

<script>
export default {
  name: 'Column',
  props: {
    wrap: {
      type: Boolean,
    },
    align: {
      type: String,
    },
    justify: {
      type: String,
    },
    full: {
      type: Boolean,
    },
  },
  computed: {
    classList() {
      let list = [];
      if (this.wrap) list = [...list, 'column-wrap'];
      if (this.align) list = [...list, `column__align-${this.align}`];
      if (this.justify) list = [...list, `column__justify-${this.justify}`];
      if (this.full) list = [...list, 'column__full'];
      return list;
    },
  },
};
</script>
